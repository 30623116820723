import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87')
];

export const server_loads = [2,4];

export const dictionary = {
		"/(app)": [9,[2],[3]],
		"/(app)/accelerator/companies": [10,[2,4],[3]],
		"/(app)/accelerator/companies/edit/[slug]": [~12,[2,4],[3]],
		"/(app)/accelerator/companies/new": [~13,[2,4],[3]],
		"/(app)/accelerator/companies/[slug]": [11,[2,4,5],[3]],
		"/(app)/accelerator/demo-day": [14,[2,4],[3]],
		"/(app)/accelerator/founders": [15,[2,4],[3]],
		"/(app)/accelerator/investors": [16,[2,4],[3]],
		"/(app)/accelerator/investors/edit/[slug]": [~19,[2,4],[3]],
		"/(app)/accelerator/investors/new": [~20,[2,4],[3]],
		"/(app)/accelerator/investors/review": [21,[2,4],[3]],
		"/(app)/accelerator/investors/review/[slug]": [~22,[2,4],[3]],
		"/(app)/accelerator/investors/review/[slug]/edit/[id]": [~23,[2,4],[3]],
		"/(app)/accelerator/investors/[slug]": [17,[2,4,6],[3]],
		"/(app)/accelerator/investors/[slug]/reviews": [18,[2,4,6],[3]],
		"/(app)/accelerator/mentor-referrals": [24,[2,4],[3]],
		"/(app)/accelerator/onboarding": [~25,[2,4],[3]],
		"/(app)/accelerator/resources": [26,[2,4],[3]],
		"/analytics": [83],
		"/(app)/announcements": [27,[2],[3]],
		"/(app)/companies/[...rest]": [28,[2],[3]],
		"/components": [84],
		"/demo-day": [85],
		"/demo-day/complete-profile": [86],
		"/demo-day/login": [87],
		"/(auth)/email-confirmation": [68],
		"/(auth)/email-confirmation/failed": [69],
		"/(auth)/email-confirmation/request-verification": [70],
		"/(auth)/email-confirmed": [71],
		"/(app)/eternal": [29,[2],[3]],
		"/(app)/eternal/dev": [~30,[2],[3]],
		"/(app)/eternal/project": [~31,[2],[3]],
		"/(app)/forums/[type]/[[name]]": [~32,[2],[3]],
		"/(app)/founders": [33,[2],[3]],
		"/(app)/hackathon": [~34,[2],[3]],
		"/(app)/hackathon/dev": [~35,[2],[3]],
		"/(app)/hackathon/hall-of-fame": [~36,[2],[3]],
		"/(app)/hackathon/onboarding": [~37,[2],[3]],
		"/(app)/hackathon/project": [~38,[2],[3]],
		"/(app)/hackathon/project/editor": [~39,[2],[3]],
		"/(app)/hackathon/project/submit": [~40,[2],[3]],
		"/(app)/hackathon/register": [~41,[2],[3]],
		"/(app)/investors/[...rest]": [42,[2],[3]],
		"/(app)/messages": [43,[2],[3]],
		"/(auth)/oauth-callback/github": [72],
		"/(auth)/oauth-callback/google": [73],
		"/(app)/onboarding": [~44,[2],[3]],
		"/(app)/onboarding/complete-your-profile": [~45,[2],[3]],
		"/(app)/onboarding/details": [~46,[2],[3]],
		"/(app)/onboarding/profile": [~47,[2],[3]],
		"/(app)/posts": [~48,[2],[3]],
		"/(app)/posts/[id]": [~49,[2],[3]],
		"/(app)/posts/[id]/comments/[commentId]": [50,[2],[3]],
		"/(app)/profiles": [51,[2],[3]],
		"/(app)/profiles/404": [52,[2],[3]],
		"/(app)/profiles/[username]": [53,[2,7],[3]],
		"/(app)/profiles/[username]/activity": [~54,[2,7,8],[3]],
		"/(app)/profiles/[username]/activity/comments": [55,[2,7,8],[3]],
		"/(app)/projects": [~56,[2],[3]],
		"/(app)/projects/editor": [~57,[2],[3]],
		"/(app)/projects/explore": [58,[2],[3]],
		"/(app)/projects/explore/[slug]": [59,[2],[3]],
		"/(app)/projects/hackathon/[slug]": [~60,[2],[3]],
		"/(app)/projects/hall-of-fame": [~61,[2],[3]],
		"/(app)/projects/success": [62,[2],[3]],
		"/(app)/referrals": [63,[2],[3]],
		"/(auth)/refresh-session": [74],
		"/(app)/register": [~64,[2],[3]],
		"/(app)/reports": [65,[2],[3]],
		"/(auth)/reset-password": [75],
		"/(auth)/reset-password/link-expired": [76],
		"/(auth)/reset-password/link-sent/[email]": [77],
		"/(auth)/reset-password/new": [78],
		"/(auth)/reset-password/success": [79],
		"/(app)/settings": [66,[2],[3]],
		"/(auth)/signin": [80],
		"/(auth)/signin/verify-magic-link": [81],
		"/(auth)/signup": [82],
		"/(app)/support": [67,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';